
























import { defineComponent, SetupContext } from '@vue/composition-api'
import VueSlickCarousel from 'vue-slick-carousel'

import { carouselProps, useCarousel } from './Carousel.hooks'
import { CarouselProps } from './Carousel.contracts'

/**
 * Component capable to render `Carousel` element.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl >
 */
export const Carousel = defineComponent({
  name: 'Carousel',
  props: carouselProps,
  components: { VueSlickCarousel },

  setup (props: CarouselProps, ctx: SetupContext) {
    return useCarousel(props, ctx.emit)
  }
})
export default Carousel
